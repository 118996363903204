import React from 'react';
// import { Link } from 'react-router-dom';
import { CiFacebook } from "react-icons/ci";
import { CiInstagram } from "react-icons/ci";
import imgLogo from '../imgs/rehabilitation-center-logo.png'
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <footer className='footer'>
      <div className='footer__all__items__container'>
        <div>
          <div className='footer__contact__info'>
            <logo className='footer__logo'><img src={imgLogo} alt="Logo" /><p>HC Rehab Center</p></logo>
            <p>
              Address: <br />
              2512 W Virginia Ave, <br />
              Tampa FL, USA 33607 <br /> <br />
              Phone: <br />
              <a href="tel:(813) 353-1380">(813) 353-1380</a> <br />
              Fax: <br />
              <a href="tel:(813) 353-1381">(813) 353-1381</a> <br />
              Email: <br />
              <a href="mailto:healthclinicrehab@gmail.com">healthclinicrehab@gmail.com</a>
            </p>
            <Link className='btn__cta__border__mantis' to="/contact">Contact Us</Link>
          </div>
        </div>

        <div className='social__container'>
          <div>
            <h3>Connect With Us</h3>
            <a href="https://www.facebook.com/profile.php?id=100093984336430" target='blank'><CiFacebook className='f__icon' /></a>  
            <a href="https://www.instagram.com/healthclinicrehab/" target='blank'><CiInstagram className='i__icon' /></a>  
          </div>

        </div>
      </div>

      <div>
        <p>
        HC Rehab Center in Tampa, Florida, offers comprehensive rehabilitation services tailored to your needs. Our expert team specializes in car accident recovery, physical therapy, chiropractic care, and massages at our state-of-the-art rehab clinic. We provide personalized care plans designed to address your unique challenges and support your journey to optimal health and well-being. Whether you're recovering from a car accident, dealing with chronic pain, or seeking holistic healing, trust HC Rehab Center for exceptional care and proven results. Contact us today to learn more about our services and start your path to recovery.
        </p>
      </div>

      <div className='footer__date__priv'>
        <p>&copy; 2022 - {new Date().getFullYear()} Health Clinic Rehabilitation Center. All rights reserved.</p>
        <p>Design by:<a href="https://64bittechnology.com" target='blank'> 64bit-technology</a> </p> 
      </div>
        <details>
            <summary>Privacy Policy</summary>
            <p>"At Health Clinic Rehabilitation Center, we are dedicated to safeguarding the privacy and confidentiality of our patients' personal information. Our commitment to your privacy is outlined in this Privacy Policy, which explains how we collect, utilize, and protect your data. By availing our services, you consent to the practices described herein. We may gather personal information such as your name, contact details, and medical history to provide medical treatment and rehabilitation services, coordinate appointments, facilitate billing and insurance claims, and enhance our services based on your needs. We have implemented security measures to ensure the protection of your personal information against unauthorized access or disclosure. We may share your information with authorized medical personnel, insurance providers, and regulatory authorities for the purpose of your medical treatment and billing. Additionally, we use cookies and analytics tools to enhance the user experience and collect information about how our website is used. You have the right to access, update, or delete your personal information, and you can also choose to opt out of marketing communications. This Privacy Policy may be updated to reflect changes in our practices, so we recommend periodic review for any updates. If you have questions or concerns about our Privacy Policy, please don't hesitate to contact us."
            <a class="clean__text" href="tel:(813) 353-1380">(813) 353-1380</a></p>
        </details>
    </footer>  
  )
}

export default Footer;