import React from 'react';
import './about.css';
import { Link } from 'react-router-dom';
import HandleNavegation from '../components/HandleNavegation';

const About = () => {
  return (
    <div>
      <section className='about__landing__container'>
        <div>
          <h1>About</h1>
          </div>
      </section>

      <section className='about__desc__1'>
        <h1>Our Missions:</h1>
        <p>At HC Rehab Center, our mission is to provide unparalleled rehabilitation services focused on restoring function, promoting healing, and enhancing overall well-being. With a commitment to excellence and compassion, we strive to empower individuals to regain control of their lives after injury or illness.</p>
        <Link className='btn__cta__no__border' to="/services" onClick={HandleNavegation()}>Learn More</Link>
      </section>

      <section className='about__desc__2'>
        <div>
          <h2>Our Approach:</h2>
          <p>At HC Rehab Center, we believe in a holistic approach to rehabilitation that addresses the physical, emotional, and psychological aspects of healing. Our team of skilled therapists, chiropractors, and massage specialists collaborate to develop personalized treatment plans tailored to each patient's unique needs and goals.</p>
          </div>
        <div>
          <h2>Our Services:</h2>
          <p>HC Rehab Center offers a comprehensive range of rehabilitation services designed to address a variety of conditions and injuries. From physical therapy and chiropractic care to massage therapy and specialized rehabilitation programs, we provide the expertise and support necessary for optimal recovery.</p>
          </div>
        <div>
          <h2>Our Team:</h2>
          <p>Our team at HC Rehab Center is comprised of experienced and compassionate professionals dedicated to helping our patients achieve their rehabilitation goals. With expertise in a variety of disciplines, including physical therapy, chiropractic care, and massage therapy, we work together to provide the highest standard of care.</p>
          </div>
      </section>

      <section className='about__desc__3'>
        <div>
          <h2>Our Facility:</h2>
          <p>Located in a state-of-the-art facility equipped with the latest technology and equipment, HC Rehab Center offers a welcoming and comfortable environment for rehabilitation. Our spacious treatment rooms and exercise areas are designed to promote healing and enhance the rehabilitation process.</p>
        </div>
        <div>
          <h2>Our Commitment to You:</h2>
          <p>At HC Rehab Center, we are committed to providing exceptional care and support to every patient who walks through our doors. From your initial consultation to your final discharge, we will be with you every step of the way, guiding you towards a healthier, more active lifestyle. Trust HC Rehab Center to be your partner in rehabilitation and wellness.</p>
        </div>
      </section>

      <section className='about__desc__4'>
        <p>"Ready to take the first step towards a healthier, more vibrant you? Don't let pain or injury hold you back any longer. Contact HC Rehab Center today and discover the transformative power of our personalized rehabilitation programs. With our expert team by your side, you can reclaim your strength, mobility, and overall well-being. Let's work together to unlock your full potential and embark on a journey towards lasting health and vitality. Schedule your appointment now and start living your best life."</p>
        <Link className='btn__cta__no__border' to="/contact" onClick={HandleNavegation()}>Contact Us!</Link>
      </section>
    </div>
  )
}

export default About; 