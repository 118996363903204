
const HandleNavegation = () => {
    window.scroll({top: 0})
}


export default HandleNavegation;






