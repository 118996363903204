import React, { useState, useEffect } from 'react';
import './testimonial.css';
import imgTes1 from '../imgs/profile picture woman glasses.jpg'
import imgTes2 from '../imgs/profile picture woman photographer.jpg'
import imgTes3 from '../imgs/profile revew man.jpg'

const Testimonial = () => {
    const [index, setIndex] = useState(0);
    const arrTestimonial = [
        <div className='testimonial__card__container__all bg__light__green'>
            <img className='testimonial__img__all' src={imgTes1} alt="Maria-review" /> 
            <span className='star__rating'>★ ★ ★ ★ ★</span> 
            <h3>Maria Jackson</h3>
            <p>I was in a serious car accident and needed rehabilitation for my injuries. The staff at this clinic were incredibly helpful and supportive throughout my recovery. They helped me regain my strength and mobility, and I'm grateful for their expertise and care.</p>
        </div>,
        <div className='testimonial__card__container__all bg__mantis__green'>
            <img className='testimonial__img__all' src={imgTes2} alt="Jessica-review" /> 
            <span className='star__rating'>★ ★ ★ ★ ★</span> 
            <h3>Jessica McClaine</h3>
            <p>After my car accident, I was struggling with chronic pain and limited mobility. But the team at this clinic worked with me to develop a personalized treatment plan that helped me overcome my injuries. Thanks to their dedication and expertise, I'm now feeling better than ever.</p>
        </div>,
        <div className='testimonial__card__container__all bg__moss__green' >
            <img className='testimonial__img__all' src={imgTes3} alt="Rafael-review" /> 
            <span className='star__rating'>★ ★ ★ ★ ★</span> 
            <h3>Rafael Johnson</h3>
            <p>I was nervous about starting rehab after my car accident, but the team at this clinic put me at ease right away. They explained everything clearly and made sure I felt comfortable and supported throughout my recovery. Thanks to their help, I'm back to my normal routine and feeling great.</p>
        </div>
    ];
    const timeRunning = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % arrTestimonial.length);
        }, timeRunning);

        return () => clearInterval(interval);
    }, );

    return (
        <div className='testimonial__main__container'>
            <h2>What Our Customers Are Saying!</h2>
            <div className='testimonial__div__container'>
                <div className='testimonial__div'>{arrTestimonial[index]}</div>
            </div>
        </div>
    );
};

export default Testimonial;
