import React from 'react';
import './services.css';
import { Link } from 'react-router-dom';
import HandleNavegation from '../components/HandleNavegation';

const Services = () => {
  return (
    <div>
      <section className='services__container__landing'>
        <h1>Comprehensive Rehabilitation Services at HC Rehab Center: Tailored Care for Your Recovery</h1>
        <p>HC Rehab Center offers a comprehensive range of rehabilitation services aimed at addressing various conditions and injuries. Our team of therapists, chiropractors, and massage specialists collaborates to provide personalized treatment plans tailored to your unique needs and goals. Whether you're recovering from a car accident, slip and fall, sports injury, or seeking relief from chronic pain, trust HC Rehab Center to provide the compassionate care and support you need to regain your quality of life.</p>
        <Link className='btn__cta__no__border services__landing__btn' to="/contact" onClick={HandleNavegation()}>Learn More</Link>
      </section>

      <section className='services__container__1'>

        <div className='services__item__1__1'>
          <h2>Auto Accident Rehabilitation Services at HC Rehab Center: Regaining Mobility and Wellness</h2>
          <p>At HC Rehab Center, we specialize in providing comprehensive rehabilitation services tailored to individuals who have experienced auto accidents. Our dedicated team understands the complexities of auto accident injuries, from whiplash to musculoskeletal issues, and we're here to help you regain mobility and wellness. Through personalized treatment plans incorporating physical therapy, chiropractic care, and targeted massages, we aim to alleviate pain, restore function, and improve overall quality of life. Trust HC Rehab Center to guide you through every step of your recovery journey, helping you get back on the road to wellness.</p>
          <span>
          <Link className='btn__cta__border' to="/contact" onClick={HandleNavegation()}>Talk to Us</Link>
          </span>
        </div>

        <div>
          <div className='services__container__1__bg__img'>
            {/* <img src={accidentOne} alt="accident-scenn" /> */}
          </div>
        </div>

      </section>

      <section className='services__container__2'>
        <div>
          <h2>Slip and Fall Rehabilitation Services at HC Rehab Center: Restoring Stability and Independence</h2>
          <p>Slip and fall accidents can lead to a range of injuries, impacting your stability and independence. At HC Rehab Center, we're dedicated to helping you overcome the challenges of slip and fall injuries and regain your quality of life. Our experienced therapists and chiropractors specialize in providing personalized rehabilitation services tailored to your specific needs. Through a combination of physical therapy, chiropractic adjustments, and targeted massages, we work to restore function, alleviate pain, and prevent future injuries. Trust HC Rehab Center to support you on your journey to recovery and help you regain stability and independence.</p>
        </div>
        <div>
          <h2>Expert Rehabilitation Services for Medical Report Needs at HC Rehab Center</h2>
          <p>Navigating rehabilitation after an injury can be challenging, especially when following a medical report. At HC Rehab Center, we specialize in providing expert rehabilitation services tailored to your specific medical needs. Our experienced team works closely with you to develop personalized treatment plans aimed at addressing the findings of your medical report. Whether you're recovering from a car accident, slip and fall, or other injury, we offer a range of services including physical therapy, chiropractic care, and massages to help you regain strength, mobility, and overall well-being. Trust HC Rehab Center to provide the support and expertise you need on your journey to recovery</p>
        </div>
        <div>
          <h2>Comprehensive Physical Therapy Services at HC Rehab Center: Restoring Function and Mobility</h2>
          <p>Physical therapy is a cornerstone of rehabilitation, helping individuals recover from injuries and regain function and mobility. At HC Rehab Center, we offer comprehensive physical therapy services tailored to your unique needs and goals. Our skilled physical therapists utilize evidence-based techniques and personalized treatment plans to address a variety of conditions, from sports injuries to post-surgical rehabilitation. Whether you're dealing with pain, limited mobility, or impaired function, we're here to help you achieve optimal outcomes and improve your quality of life. Trust HC Rehab Center to be your partner in rehabilitation and wellness.</p>
        </div>
      </section>

      <section className='services__container__1 bg__color__3'>
        <div>
          <div className='services__container__1__bg__img img__3'>
            {/* <img src={accidentOne} alt="accident-scenn" /> */}
          </div>
        </div>

        <div className='services__item__1__1'>
          <h2>Expert Chiropractic Care at HC Rehab Center: Alleviating Pain and Restoring Alignment</h2>
          <p>Chiropractic care is a holistic approach to healing that focuses on restoring proper alignment and function to the spine and musculoskeletal system. At HC Rehab Center, our expert chiropractors specialize in providing gentle and effective chiropractic care to alleviate pain and improve overall health and wellness. Whether you're suffering from back pain, neck pain, or headaches, we'll work with you to develop a personalized treatment plan tailored to your specific needs. With a focus on natural healing and non-invasive techniques, we aim to help you achieve lasting relief and optimal function. Trust HC Rehab Center to help you live pain-free and enjoy life to the fullest.</p>
          <span>
          <Link className='btn__cta__border' to="/contact" onClick={HandleNavegation()}>Talk to Us</Link>
          </span>
        </div>
        </section>

        <section className='services__container__4'>
          <h2>Therapeutic Massage Services at HC Rehab Center: Relaxation and Rehabilitation</h2>
          <p>Massage therapy offers a range of benefits for both relaxation and rehabilitation. At HC Rehab Center, we offer therapeutic massage services designed to promote healing, reduce pain, and improve overall well-being. Our skilled massage therapists specialize in a variety of techniques, including deep tissue massage, Swedish massage, and sports massage, to address your specific needs and goals. Whether you're recovering from an injury, managing chronic pain, or simply seeking relaxation and stress relief, we'll customize a massage therapy plan to suit your preferences and help you feel your best. Trust HC Rehab Center to provide the expert care and personalized attention you deserve.</p>
          <Link className='btn__cta__sc4' to="/contact" onClick={HandleNavegation()}>Call Now</Link>
        </section> 

    </div>
  )
}

export default Services;