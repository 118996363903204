import React, { useState } from 'react';
import './navbar.css';
import imgLogo from "../imgs/rehabilitation-center-logo.png";
import { Link } from 'react-router-dom';
import HandleNavegation from '../components/HandleNavegation';

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false); // State to track whether the element is expanded or collapsed

  const closeMenu = () => {
    setIsExpanded(false);
  }

  const toggleHeight = () => {
    setIsExpanded(!isExpanded); // Toggle the state when the button is clicked
  };

  return (
    <div>
      <nav>
        <div className='nav__logo__p__container'>
          <img src={imgLogo} alt="logo-img" />
          <p>HC-Rehab-Center</p>
        </div>
        <div className={`nav__container__ul ${isExpanded ? 'active' : ''}`}>
          <ul>
            <li className='nav__li__all'><Link to="/" onClick={ () => {closeMenu()}}>Home</Link></li>
            <li className='nav__li__all'><Link to="/about" onClick={ () => {closeMenu()}}>About</Link></li>
            <li className='nav__li__all'><Link to="/services" onClick={ () => {closeMenu()}}>Services</Link></li>
            <li className='nav__li__all'><Link to="/contact" onClick={ () => {closeMenu()}}>Contact</Link></li>
          </ul>
          <Link to="/contact" className='btn__cta__no__border btn__cta__spam' onClick={HandleNavegation()}>Talk To Us</Link> 
        </div>

        <div className='btn__menu' onClick={toggleHeight}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
