import React from 'react';
import './contact.css';

const Contact = () => {

  return (
    <div>
      <section className='contact__landing'>
        <h1>Get in Touch with HC Rehab Center: <br /> Your Path to Wellness Starts Here</h1>
        <p>Ready to start your journey towards improved health and vitality? Contact HC Rehab Center today to schedule an appointment or learn more about our rehabilitation services. Our compassionate team is here to support you every step of the way, offering personalized care and expert guidance to help you achieve your wellness goals. Whether you're recovering from an injury, managing chronic pain, or simply seeking to enhance your overall well-being, HC Rehab Center is committed to providing the highest quality care tailored to your unique needs. Reach out to us now and take the first step towards a healthier, happier you.</p>
        <a className='btn__cta__no__border' href="tel:(813) 353-1380">Call Us!</a>

      </section>
      <section className='contact__container__1'>
        <div className='contact__container__form'>
          <h2>contact us</h2>
          <p>we are ready to lead you into wellness</p>
          <form action="https://formsubmit.co/healthclinicrehab@gmail.com" method="POST">
            <input type="text" name='name' placeholder='Enter Your Name' required /> <br />
            <input type="text" name='email' placeholder='Enter Your Email' required /> <br />
            <textarea name="" id="" cols="30" rows="10" placeholder='Enter Your Message' required></textarea> <br />
            <button type='submit'>send message</button>
          </form>
        </div>
      </section>

      <section>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.4603442731477!2d-82.48874898816575!3d27.97980901332029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2c3ed7ddc9429%3A0x62172cd01158542a!2s2512%20W%20Virginia%20Ave%2C%20Tampa%2C%20FL%2033607!5e0!3m2!1sen!2sus!4v1708473328258!5m2!1sen!2sus" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>

      <section className='contact__calendly'>
        <div className='make__appt'>
          <div>
            <h3>Schedule Your Appoinment Today!</h3>
            <a href="https://calendly.com/healthclinicrehab/appointment" target='__blank'>Book Your Appointment</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact; 